import { ItemsArticles } from '@/robot/backendSchemas';
import { format } from 'date-fns';
import { micromark } from 'micromark';

export const stringToSlug = (string: string) => {
  if (!string) return "";
  return string.toLocaleLowerCase().replace(/\s/g, "-");
};

const capitalizeFirstLetter = (string: string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const slugToString = (string: string) => {
  if (!string) return "";
  return capitalizeFirstLetter(string.replace("-", " "));
};

export const capitalizeFirstLetterOfEveryWord = (string: string) => {
  if (!string) return "";
  const words = string.split(" ");
  words.forEach((word, index) => {
    words[index] = capitalizeFirstLetter(word);
  });
  return words.join(" ");
};

export const nl2br = (string: string) => {
  return string.replace(/\n/g, "<br />");
};

export const pluralize = (count: number, singular: string, plural: string) => {
  return `${count} ${count > 1 ? plural : singular}`;
};

export const fullName = (firstName: string | null, lastName: string | null, fallback: string = "") => {
  if (!firstName || (!firstName && !lastName)) return fallback;
  if (!lastName) return firstName;

  return `${firstName} ${lastName}`;
}

export const wrapTables = (html: string, wrapperClass: string): string => {
  const tableStartTag = "<table";
  const tableEndTag = "</table>";

  let result = "";
  let currentPosition = 0;

  while (currentPosition < html.length) {
    const startIdx = html.indexOf(tableStartTag, currentPosition);
    if (startIdx === -1) {
      result += html.substring(currentPosition);
      break;
    }
    const endIdx = html.indexOf(tableEndTag, startIdx) + tableEndTag.length;

    result += html.substring(currentPosition, startIdx);
    result += `<div class="${wrapperClass}">`;
    result += html.substring(startIdx, endIdx);
    result += "</div>";

    currentPosition = endIdx;
  }

  return result;
};

/**
 * Create a teaser from an HTML string
 * @param html 
 * @param maxLength 
 * @returns string
 */
export const createTeaser = (html: string, maxLength: number = 150): string => {
  const text = html.replace(/<\/?[^>]+(>|$)/g, "");

  return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};

export const md2html = (markdown: string): string => {
  return micromark(fixBoldFormatting(markdown));
}

/**
 * Fix bold formatting in markdown text by removing extra spaces around the asterisks
 * @param text The markdown text
 * @returns string The fixed markdown text
 */
export const fixBoldFormatting = (text: string) => {
  return text.replace(/\*{1,2}\s*(\S[\s\S]*?\S)\s*\*{1,2}/g, '**$1**');
};

/**
 * Generate a citation for an article in the following format [number]: author. (year). title. Retrieved date, from url
 * @param article The article to generate the citation for
 * @param number  The number of the citation
 * @returns  string The citation string in the following format [number]: author. (year). title. Retrieved date, from url
 */
export const generateCitation = (article: ItemsArticles, number: number): string => {
  const rawData: any = article.raw_data || {};

  const author = rawData?.metadata?.author?.trim() || null;
  const title = article.title?.trim() || null;
  const url = article.url;
  const retrievalDate = format(rawData.crawl?.loadedTime || new Date(), 'MMMM d, yyyy');
  const publishDate = article.crawled_at ? format(new Date(article.crawled_at), 'yyyy, MMMM d') : null;

  return `[${number}]: ${author ? `${author}. ` : ''}${publishDate ? `(${publishDate}). ` : ''}${title}. Retrieved ${retrievalDate}, from ${url}`;
}