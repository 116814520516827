import {
    AuthenticationClient,
    DirectusClient,
    RestClient,
    aggregate,
    authentication,
    createDirectus,
    readFiles,
    readItem,
    readItems,
    rest,
    staticToken,
} from '@directus/sdk';
import { Files, ItemsContentFiles } from '../robot/backendSchemas';
import { ItemsArticleSources, ItemsArticles, ItemsCMSPage, ItemsCategory, ItemsChat, ItemsContent, ItemsGlobal, ItemsHomepage, ItemsMonitoringroom, ItemsNavigations, ItemsSanctions, ItemsThoughtroom, ItemsTopic, ItemsWikiPage } from '@/robot/backendSchemas';
import { ReadonlyURLSearchParams, redirect } from 'next/navigation';
import env, { isServer } from '@/env';

import { cache } from 'react';
import { getServerSession } from 'next-auth';
import { linkifyCMSPage } from './linkUtils';
import { options } from '@/app/api/auth/[...nextauth]/options';
import { pickFromArray } from './arrayUtils';
import pluralizeLibrary from 'pluralize';
import { stringToSlug } from './stringUtils';

// Client for public requests
export const directus = createDirectus(isServer() ? env.urls.backend : env.urls.backendClient).with(rest());

const authenticatedUrl = new URL('/', env.urls.backend);
authenticatedUrl.searchParams.set('access_token', process.env.DIRECTUS_ADMIN_TOKEN || '');

// Client for adminRequests
export const adminDirectus = createDirectus(authenticatedUrl.toString()).with(rest()).with(authentication());
adminDirectus.setToken(process.env.DIRECTUS_ADMIN_TOKEN || '');

export const getGlobals = async () => {
    await avoidRateLimit();

    return directus.request<ItemsGlobal>(readItems('global'));
}


export async function getDirectusFrontendClient(token: string) {
    if (!token) throw new Error('No token provided');
    return createDirectus(isServer() ? env.urls.backend : env.urls.backendClient).with(staticToken(token)).with(rest());
}

export const directusFrontendAuth = createDirectus(isServer() ? env.urls.backend : env.urls.backendClient).with(authentication('cookie', {
    credentials: 'include', autoRefresh: true
})).with(rest());

type DirectusClientType = DirectusClient<any> & AuthenticationClient<any> & RestClient<any>;


export const getDirectusFrontendAuth = async (): Promise<DirectusClientType> => {
    const directus = createDirectus(isServer() ? env.urls.backend : env.urls.backendClient)
        .with(authentication('cookie', {
            credentials: 'include', autoRefresh: true
        }))
        .with(rest());

    if (isServer()) {
        try {
            const session = await getServerSession(options);
            // console.log({ getDirectusFrontendAuth: session });

            if (session?.accessToken) {
                directus.setToken(session.accessToken);
            } else {
                console.warn('No access token found in session');
                redirect(`/login?message=${encodeURIComponent('Please login to continue.')}`);
            }
        } catch (error) {
            console.error('Error in getDirectusFrontendAuth:', error);
            throw error; // Re-throw the error to be handled by the caller
        }
    } else {
        // Client-side: The cookie-based authentication should work automatically
        console.log({ getDirectusFrontendAuth: { clientCookie: await directus.getToken() } });
    }

    return directus;
};

// export const useDirectus = async <T>(callback: (client: DirectusClientType) => Promise<T>): Promise<T> => {
//     const client = await getDirectusFrontendAuth();
//     return callback(client);
// };

export type SingleNavItem = {
    label: string,
    url: string,
    existing_page?: {
        key: number,
        collection: string
    }
    submenu?: SingleNavItem[]
    highlight?: boolean
}

// This exists because the schema codegen didn't pick up the fact that the items field is an array of objects.
export type FixedItemsNavigations = ItemsNavigations & {
    items: SingleNavItem[]
}

export const getNavigation = async (title: string) => {
    await avoidRateLimit();

    const results = await directus.request<FixedItemsNavigations[]>(readItems('navigations', {
        fields: ['*.*'],
        limit: 1
    }));

    if (!results) return null;

    for (let i = 0; i < results[0].items.length; i++) {
        const existingPage = results[0].items[i].existing_page
        if (existingPage) {

            const pageData = await directus.request<ItemsCMSPage>(readItem('cms_page', String(existingPage.key)))
            if (pageData.nav_title) results[0].items[i].label = pageData.nav_title;
            results[0].items[i].url = linkifyCMSPage(pageData.slug || stringToSlug(pageData.title || 'not-found'));
        }
    }

    return results[0];
}

export const getCMSPage = async (slug: string) => {
    await avoidRateLimit();

    return directus.request<ItemsCMSPage[]>(readItems('cms_page', {
        fields: ['*', 'hero.*', { seo: ['title', 'meta_description', 'no_index', 'no_follow', 'og_image'] }],
        filter: {
            slug
        }
    }));
}

export const getLatestWikiArticles = async (limit: number = 3) => {
    await avoidRateLimit();

    return directus.request<ItemsWikiPage[]>(readItems('wiki_page', {
        fields: ['hero', 'slug', 'date_updated', 'title', 'teaser_text', 'content', 'categories.*.*'],
        sort: '-date_updated',
        limit
    }));
}

export const getWikiArticle = async (slug: string) => {
    await avoidRateLimit();

    return directus.request<ItemsWikiPage[]>(readItems('wiki_page', {
        fields: ['*'],
        filter: {
            slug
        }
    }));
}

const sanctionRelationFields = {
    entity: ['title'],
    purpose: ['sanction_purposes_id.title'],
    sector: ['sanction_sectors_id.title'],
    type: ['sanction_types_id.title'],
};

export const getSanctionMeta = cache(async () => {
    await avoidRateLimit();

    return directus.request<ItemsSanctions[]>(readItems('sanctions', {
        fields: [sanctionRelationFields]
    }));
});

export const getSanctions = cache(async ({ page = 1, limit = 25, filters }: { page: number, limit: number, filters: ReadonlyURLSearchParams }) => {
    await avoidRateLimit();

    // I need this because of the different places this is being used.... weird shit
    let filtersObject = transformSanctionFiltersToDirectusFilters(filters);
    // filtersObject = {};
    const data = await directus.request<ItemsSanctions[]>(readItems('sanctions', {
        fields: [
            'id', 'date', 'sanction_status', 'reference', 'short_description', 'slug', 'title', 'statutory_basis', 'link', sanctionRelationFields
        ],
        limit: limit,
        offset: (page - 1) * limit,
        filter: filtersObject || {}, //{ sector: { sanction_sectors_id: { title: { _eq: "agriculture" } } } },
        search: (filters).get('search') || ""
    }));

    const total_count = await directus.request<{ count: number }[]>(aggregate('sanctions', { aggregate: { count: '*' } }));
    const unlimitedData = await directus.request<ItemsSanctions[]>(readItems('sanctions', {
        fields: ['id'],
        filter: filtersObject || {},
        search: (filters).get('search') || ""
    }));

    return new Promise<{ data: ItemsSanctions[], total_count: number, current_result_total_count: number }>(resolve => {
        resolve({
            data,
            total_count: total_count[0].count,
            current_result_total_count: unlimitedData.length
        });
    });
});

export const homepageFields = [
    '*',
    {
        blocks: [
            '*',
            {
                item: ['*.*', {
                    block_cardgroup: [ // Need to specify seperately because I can't fetch 3 levels deep for related items.
                        '*.*',
                        'cards.*',
                        'cards.custom_icon.*', // Fetching everything from custom_icon
                        'cards.custom_icon.directus_files_id.*'
                    ],
                }]
            }
        ],
        seo: ['*']
    }
];

export const getHomepage = async () => {
    await avoidRateLimit();

    return directus.request<ItemsHomepage>(readItems('homepage', {
        fields: homepageFields
    }));
}

export const thoughtroomFields = [
    '*', {
        teaser_blocks: [
            {
                block_category_teaser_id: [
                    '*', 'status', 'category.*'
                ],
            }
        ],
        seo: ['*']
    }
]

export const getThoughtroom = async () => {
    await avoidRateLimit();

    return directus.request<ItemsThoughtroom>(readItems('thoughtroom', {
        fields: thoughtroomFields
    }));
}

export const thoughtroomCategoryFields = ['*', {
    hero: ['id', 'width', 'height', 'focal_point_x', 'focal_point_y'],
    seo: ['*']
}];

export const getThoughtroomCategory = async (slug: string) => {
    await avoidRateLimit();

    const results = await directus.request<ItemsCategory[]>(readItems('category', {
        fields: thoughtroomCategoryFields,
        filter: {
            slug
        },
    }));

    if (!results || results.length === 0) return null;

    const ret = results[0];

    // Not feasable at the moment as this would mean we need to open the users collection up to the public. Keeping it here for guidance on the return type handling.
    // const authorUser = await directus.request<Pick<DirectusUser<any>, 'first_name' | 'last_name'>>(readUser(ret.user_created!, {
    //     fields: ['first_name', 'last_name']
    // }));

    // ret.user_created = fullName(authorUser.first_name, authorUser.last_name, 'Unknown Author');

    return ret;
}

export const thoughtroomContentfields = ['*', {
    attachments: ['*'],
    category: ["title", "slug", "intro_text"],
    hero_image: ['id', 'width', 'height', 'focal_point_x', 'focal_point_y'],
    seo: ['*'],
}];

export const getThoughtroomContent = async (slug: string) => {
    await avoidRateLimit();

    const results = await directus.request<ItemsContent[]>(readItems('content', {

        fields: thoughtroomContentfields,
        filter: {
            slug
        }
    }));

    if (!results || results.length === 0) return null;


    // process attachments
    const resultAttachments = (results[0].attachments as ItemsContentFiles[])?.map(attachment => attachment.directus_files_id as string) || [""];

    // escape early if no attachments
    if (resultAttachments.length === 0) return results[0];

    const files = await directus.request<Files[]>(readFiles({
        filter: {
            id: {
                _in: resultAttachments
            }
        },
        fields: ['*']
    }));

    return { ...results[0], attachments: files };
}

export const getFeaturedThoughtRoomContentByCategory = async (categorySlug: string) => {
    await avoidRateLimit();

    return directus.request<ItemsContent[]>(readItems('content', {
        fields: thoughtroomContentfields,
        filter: {
            category: {
                slug: categorySlug
            },
            is_featured: true,
            status: 'published',
        },
        limit: 3,
        sort: '-publication_date',

    }));

}

export const getThoughtRoomContentByCategory = async (categorySlug: string, limit: number, search?: string, page?: number) => {
    await avoidRateLimit();

    const additionalProps: { [key: string]: any } = {};
    if (page) {
        additionalProps['offset'] = (page - 1) * limit;
    }

    if (search) {
        additionalProps['search'] = search;
    }

    // const currentCount = await directus.request<{ count: string }[]>(readItems('content', {
    //     aggregate: {
    //         count: '*'
    //     },
    //     filter: {
    //         category: {
    //             slug: categorySlug
    //         },
    //         status: 'published',
    //     },
    //     ...additionalProps,
    // }));

    const totalCount = await directus.request<{ count: string }[]>(aggregate('content', {
        aggregate: {
            count: '*',
        },
        query: {
            filter: {
                category: {
                    slug: categorySlug
                },
                status: 'published',
            },
            search: search
        }
    }));

    const data = await directus.request<ItemsContent[]>(readItems('content', {
        fields: thoughtroomContentfields,
        filter: {
            category: {
                slug: categorySlug
            },
            status: 'published',
        },
        sort: '-publication_date',
        limit,
        ...additionalProps,
    }));

    console.log({ totalCountAPI: totalCount, page: page, limit: limit, search: search });

    // Return data and total count as promise
    return new Promise<{ data: ItemsContent[], totalCount: number, totalPages: number }>(resolve => {
        resolve({
            data,
            totalCount: parseInt(totalCount[0]?.count || "0", 10),
            totalPages: Math.ceil(parseInt(totalCount[0]?.count || "0", 10) / limit)
        });
    });

}

export const monitoringroomFields = [
    '*', {
        seo: ['*']
    }
]

export const getMonitoringroom = async () => {
    await avoidRateLimit();

    return directus.request<ItemsMonitoringroom>(readItems('monitoringroom', {
        fields: monitoringroomFields
    }));
}

export const articleFields = ['id', 'title', 'url', 'language', 'content', 'created_at', 'crawled_at', 'weaviate_ids', 'article_source.*'];

export const getArticles = async (search: string, page: number, limit: number) => {
    await avoidRateLimit();

    const additionalProps: { [key: string]: any } = {};
    if (page) {
        additionalProps['offset'] = (page - 1) * limit;
    }

    if (search) {
        additionalProps['search'] = search;
    }

    const totalCount = await directus.request<{ count: string }[]>(aggregate('articles', {
        aggregate: {
            count: '*',
        },
        query: {
            search: search
        }
    }));

    const data = await directus.request<ItemsArticles[]>(readItems('articles', {
        fields: articleFields,
        sort: '-crawled_at',
        limit,
        ...additionalProps,
    }));

    // Return data and total count as promise
    return new Promise<{ data: ItemsArticles[], totalCount: number, totalPages: number }>(resolve => {
        resolve({
            data,
            totalCount: parseInt(totalCount[0]?.count || "0", 10),
            totalPages: Math.ceil(parseInt(totalCount[0]?.count || "0", 10) / limit)
        });
    });
}

export const getArticlesByIds = async (ids: string[], sort: string | null = '-crawled_at') => {
    await avoidRateLimit();


    console.log({ usingSortValuie: sort });

    const options: { [k: string]: any } = {}
    if (sort) {
        options['sort'] = sort;
    }

    console.log()

    return directus.request<ItemsArticles[]>(readItems('articles', {
        fields: pickFromArray(articleFields, 'id', 'title', 'url', 'language', 'content', 'created_at', 'crawled_at', 'weaviate_ids'),
        filter: {
            id: {
                _in: ids.join(',')
            }
        },
        ...options
    }));
}

export const getArticleLanguages = async () => {
    await avoidRateLimit();

    try {
        const result = await directus.request(readItems('articles', {
            fields: ['language'],
            filter: {
                language: {
                    _nnull: true,
                    _neq: 'undefined'
                }
            },
            groupBy: ['language'],
            limit: -1,
        }));

        return result.map(item => item.language);
    } catch (e) {
        console.error("GetArticleLanguages Errror", e);
        return [];
    }
}

export const getArticleSourceUrls = async (): Promise<Pick<ItemsArticleSources, "id" | "start_url">[]> => {
    await avoidRateLimit();

    try {
        const result = await directus.request<ItemsArticleSources[]>(readItems('article_sources', {
            fields: ['id', 'start_url'],
            filter: {
                status: {
                    _eq: 'active'
                }
            },
            limit: -1,
            sort: 'start_url',
        }));

        if (!result) return [];

        const uniqueEntries = new Set<string>();

        const dedupedResult = result.reduce((acc: Pick<ItemsArticleSources, "id" | "start_url">[], cur: Pick<ItemsArticleSources, "id" | "start_url">) => {
            if (!cur.start_url) return acc;

            if (!uniqueEntries.has(cur.start_url)) {
                uniqueEntries.add(cur.start_url);
                acc.push(cur);
            }
            return acc;
        }, [])


        return dedupedResult;
    } catch (e) {
        console.error("GetArticleSourceUrls Errror", e);
        return [];
    }
}

export const getTopics = async () => {
    await avoidRateLimit();

    try {
        const client = await getDirectusFrontendAuth()
        return await client.request<ItemsTopic[]>(readItems('topic', {
            fields: ['id', 'title', 'slug', 'selected_articles.*.*', 'dismissed_articles.*.*', 'chats.*', 'user_created.*'],
            sort: '-date_created',
            filter: {
                user_created: {
                    _eq: '$CURRENT_USER'
                }
            }
        }
        ));
    } catch (e) {
        console.error(e)
        return [];
    }
}

export const getTopicBySlug = async (slug: ItemsTopic["slug"]): Promise<ItemsTopic> => {
    await avoidRateLimit();
    const client = await getDirectusFrontendAuth();
    const topics = await client.request<ItemsTopic[]>(readItems('topic', {
        fields: ['*', 'selected_articles.*.*', 'dismissed_articles.*.*', 'chats.*'],
        filter: {
            slug
        }
    }));
    return topics[0];
}

export const getChatsByTopicId = async (topicId: ItemsTopic["id"]) => {
    await avoidRateLimit();

    return directus.request<ItemsChat[]>(readItems('chat', {
        fields: ['*', 'chat_messages.*'],
        filter: {
            topic_id: topicId
        }
    }));
}

export const getChat = async (chatId: ItemsChat["id"]) => {
    await avoidRateLimit();

    if (!chatId) return null;
    return directus.request<ItemsChat>(readItem('chat', chatId, { fields: ['*', 'chat_messages.*'] }));
}

export const getChatMessages = async (chatId: ItemsChat["id"]) => {
    await avoidRateLimit();

    return directus.request<ItemsChat[]>(readItems('chat', {
        fields: ['*'],
        filter: {
            chat_id: chatId
        }
    }));
}

const transformSanctionFiltersToDirectusFilters = (filters: ReadonlyURLSearchParams) => {
    const keyWhitelist = ['short_description', 'title', 'sector', 'type', 'purpose', 'entity'];
    // Those need to be handled differently
    const m2mKeyWhitelist = ['sector', 'type', 'purpose'];

    /**
     * Directus Syntax: https://docs.directus.io/reference/filter-rules.html
     * 
     * {
     *   <field>: {
    *	   <operator>: <value>
    *   }
    * }
    */
    const transformed = Object.entries(convertSearchParamsToObject(filters)).reduce((acc, [key, value]) => {
        let payload: any = {
            _contains: value
        };

        if (m2mKeyWhitelist.includes(key)) {
            payload = {
                [`sanction_${pluralizeLibrary(key)}_id`]: { title: { _eq: Array.isArray(value) ? value.join(',') : value } }
            }
        }

        if (key === 'entity') {
            payload = {
                title: {
                    _contains: value
                }

            }
        }

        if (keyWhitelist.includes(key)) {
            return {
                ...acc,
                [key]: payload
            };
        }
        return acc;
    }, {});

    return transformed;
}

export const convertSearchParamsToObject = (searchParams: ReadonlyURLSearchParams): Record<string, string> => {
    const obj: Record<string, string> = {};
    searchParams.forEach((value, key) => {
        obj[key] = value;
    });
    return obj;
};

// This is a delay during build to avoid rate limiting. See: https://github.com/vercel/next.js/discussions/18550#discussioncomment-1248384
export function avoidRateLimit(delay = 1000, skipCheck = false) {
    if (!skipCheck && !process.env.IS_BUILD) {
        return
    }

    return new Promise((resolve) => {
        setTimeout(resolve, delay)
    })
}



export default directus;