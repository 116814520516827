import env, { isServer } from "@/env";
import { Files, ItemsBlockHero, ItemsWikiCategory, ItemsWikiPage } from "@/robot/backendSchemas";

import { ValidTarget } from "@/types";
import { NavItem } from "@/types/KBTypes";

export function linkifyKBArticle(article: ItemsWikiPage, category: ItemsWikiCategory | string) {
    return linkifyGeneric({ path: `/knowledge-base/${typeof category === 'string' ? category : category.slug}/${article.slug}`, target: 'frontend' });
}

export function linkifyAsset(asset: ItemsBlockHero['image'], target: ValidTarget = 'backend') {
    if (!asset) return '';

    return linkifyGeneric({ path: `/assets/${typeof asset === 'string' ? asset : asset.id}`, target: target });
}

export function linkifyWikiCategory(category: ItemsWikiCategory | NavItem) {
    return linkifyGeneric({ path: `/knowledge-base/${typeof category === 'string' ? category : category?.slug || 'unknown-category'}`, target: 'frontend' });
}

export function linkifyCMSPage(slug: string) {
    return linkifyGeneric({ path: `/p/${slug}`, target: 'frontend' });
}

export function linkifyDownloadFile(file: Files, target: ValidTarget = 'backendClient') {
    return linkifyGeneric({ path: `/assets/${file.id}/${file.filename_download}?download`, target: target });
}

export function linkifyGeneric(options: { path: string; target?: ValidTarget; base?: string; }): string {
    let url = null;
    if (options.base) {
        url = new URL(options.path, options.base);
    } else {
        if (options.target === "frontend") {
            url = new URL(options.path, env.urls.frontend);
        } else if (options.target === "backend") {
            if (isServer()) {
                url = new URL(options.path, env.urls.backend);
            } else {
                url = new URL(options.path, env.urls.backendClient);
            }
        } else if (options.target === "backendClient") {
            url = new URL(options.path, env.urls.backendClient);
        }
    }

    return url ? url.toString() : '';
}

export function linkifyText(text: string) {
    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,})/g;
    const phoneRegex = /Phone:\s*(\+?\d{1,3}[-. ()]*\d{1,4}[-. ()]*\d{1,4}[-. ()]*\d{1,4}[-. ()]*\d{0,4})/g;
    // Adjusted URL regex to match basic URL patterns; not perfect but avoids email addresses
    const urlRegex = /\b(https?:\/\/)?([\w-]+\.[\w-]+)(\/[\w\- .?%&=]*)?\b(?!\@)/g;

    // Replace emails first to avoid URL regex catching email domains
    let processedText = text.replace(emailRegex, '<a href="mailto:$1">$1</a>');

    // Replace phone numbers
    processedText = processedText.replace(phoneRegex, 'Phone: <a href="tel:$1">$1</a>');

    // Replace URLs, ensuring not to double-process anything within an <a> tag
    processedText = processedText.replace(urlRegex, (match, p1, p2, p3, offset, string) => {
        // Prepend http:// if the protocol is missing, and ensure not part of an email
        if (string.substring(offset - 1, offset) !== "@" && !p1) {
            return `<a href="https://${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
        } else if (p1) {
            return `<a href="${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
        }
        return match; // Return the match unaltered if it fails checks
    });

    return processedText;
}


