const env = {
  development: {
    settings: {
      revalidate: Number.isNaN(parseInt(process.env.REVALIDATE_DEFAULT || "0")) ? 0 : parseInt(process.env.REVALIDATE_DEFAULT || "0"),
    },
    urls: {
      backend: process.env.PROD_BACKEND_URL || process.env.NEXT_PUBLIC_PROD_BACKEND_URL || 'http://localhost:8055',
      backendClient: process.env.PROD_BACKEND_CLIENT_URL || process.env.NEXT_PUBLIC_PROD_BACKEND_CLIENT_URL || 'http://localhost:8055',
      frontend: process.env.PROD_FRONTEND_URL || process.env.NEXT_PUBLIC_PROD_FRONTEND_URL || 'http://localhost:3003',
      weaviate: process.env.WEAVIATE_URL || 'onn-weaviate',
      onn: `http://api.onn.localhost:8000/`,
      cms: `http://localhost:3003/`,
      sanctions: `http://localhost:3003/sanctions/`,
    },
  },
  production: {
    settings: {
      revalidate: Number.isNaN(parseInt(process.env.REVALIDATE_DEFAULT || "10")) ? 0 : parseInt(process.env.REVALIDATE_DEFAULT || "10"),
    },
    urls: {
      backend: process.env.PROD_BACKEND_URL || process.env.NEXT_PUBLIC_PROD_BACKEND_URL || 'https://default-PROD_BACKEND_URL_UNDEFINED',
      backendClient: process.env.PROD_BACKEND_CLIENT_URL || process.env.NEXT_PUBLIC_PROD_BACKEND_CLIENT_URL || 'http://localhost:8055',
      frontend: process.env.PROD_FRONTEND_URL || process.env.NEXT_PUBLIC_PROD_FRONTEND_URL || 'https://default-PROD_FRONTEND_URL_UNDEFINED',
      weaviate: process.env.WEAVIATE_URL || 'onn-weaviate',
      onn: `https://api.opennuclear.org/`,
      cms: `https://analysis.opennuclear.org/`, // Notes for #112:  https://analysis.opennuclear.org/
      sanctions: `https://analysis.opennuclear.org/sanctions/`, // Notes for #112:  https://analysis.opennuclear.org/sanctions/
    },
  },
};

console.log(`[env.ts]: Using this weaviate URL: ${env[process.env.NODE_ENV as "development" | "production"].urls.weaviate}`);

export const isServer = () => typeof window === "undefined";
export const isClient = () => typeof window !== "undefined";

export default env[process.env.NODE_ENV as "development" | "production"];
